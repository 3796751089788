import React from 'react';

import {useFeatures} from '@udemy/react-experimentation-provider';
import {UDData} from '@udemy/ud-data';

import {
    REMOVE_SMARTBAR_FROM_BROWSE_PAGES,
    REMOVE_SMARTBAR_FROM_BROWSE_PAGES_ROUTES,
} from 'src/lib/experimentation-constants';

export function WithoutSmartBar<T>(WrappedComponent: React.ComponentType<T>, udData?: UDData) {
    const {featureVariantAssignments, isLoading} = useFeatures([REMOVE_SMARTBAR_FROM_BROWSE_PAGES]);

    const hideSmartBarInRoute = REMOVE_SMARTBAR_FROM_BROWSE_PAGES_ROUTES.includes(
        udData?.userAgnosticTrackingParams.page_key ?? '',
    );

    const hideSmartBar =
        hideSmartBarInRoute &&
        (featureVariantAssignments.get(REMOVE_SMARTBAR_FROM_BROWSE_PAGES)?.configuration
            ?.hideSmartBar ??
            false);

    const showSmartBar = isLoading ? false : !hideSmartBar;

    return (props: T) => <WrappedComponent {...props} showSmartBar={showSmartBar} />;
}
