import {observer} from 'mobx-react';
import React from 'react';

import {TrackerInitializer} from '@udemy/event-tracking';
import {Footer} from '@udemy/footer';
import {Header} from '@udemy/react-header';
import {Toaster} from '@udemy/react-messaging-components';
import {useStores} from '@udemy/store-provider';

import {RootStore} from 'src/stores/root-store';

import {WithoutSmartBar} from '../without-smart-bar/without-smart-bar.react-component';
import styles from './layout.module.less';

export const getLayout = (page: React.ReactElement) => {
    return <Layout>{page}</Layout>;
};

const Layout = observer(({children}: {children: React.ReactElement}) => {
    const [rootStore] = useStores([RootStore]);
    return (
        <>
            {/* TODO: remove SmartBar rendering from Header component, re-add it here */}
            {/* <SmartBar /> */}
            <div className={styles['container']}>
                {WithoutSmartBar(Header, rootStore.udData)(rootStore.headerData)}
                <main className={styles['content']} id="main-content-anchor">
                    {children}
                </main>
                <Footer
                    clientProps={rootStore.footerData}
                    serverProps={rootStore.footerData}
                    limitedConsumptionTrial={true}
                />
                <Toaster />
            </div>
            <TrackerInitializer />
        </>
    );
});
